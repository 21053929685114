*, *::after, *::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "Gabarito", sans-serif;
  font-optical-sizing: auto;

}

